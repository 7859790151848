/* Header Styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #0056b3;
  color: white;
}

.header nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
}

.header nav ul li {
  display: inline;
}

.header nav ul li a {
  color: white;
  text-decoration: none;
}

.header button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.header button:hover {
  background-color: #e60000;
}
