/* LoginPage Styles */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f4f8;
}

.login-box {
  max-width: 400px;
  width: 100%;
  padding: 20px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
}

.login-box h2 {
  color: #0056b3;
}

.login-box input,
.login-box button {
  font-size: 16px;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
}

.login-box button {
  background-color: #0056b3;
  color: white;
  border: none;
  cursor: pointer;
}

.login-box button:hover {
  background-color: #004494;
}

.login-box p {
  color: red;
}
