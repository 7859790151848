/* ConfigAppPage Styles */
.config-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.config-container h2,
.config-container h3 {
  color: #0056b3;
}

.config-container label {
  display: block;
  margin: 10px 0;
}

.config-container input[type="number"],
.config-container input[type="text"],
.config-container input[type="checkbox"],
.config-container select,
.config-container button {
  font-size: 16px;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.config-container button {
  background-color: #0056b3;
  color: white;
  border: none;
  cursor: pointer;
}

.config-container button:hover {
  background-color: #004494;
}

.config-container table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.config-container table th,
.config-container table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.config-container table th {
  background-color: #f0f4f8;
}

.config-container table td {
  background-color: #fff;
}

.config-container .file-upload {
  margin: 10px 0;
}

.config-container .custom-file-upload {
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #0056b3;
  color: white;
  border-radius: 5px;
  border: none;
}

.config-container .custom-file-upload:hover {
  background-color: #004494;
}

.config-container input[type="file"] {
  display: none;
}
