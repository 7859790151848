/* UsersPage Styles */
.users-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.users-container h2 {
  color: #0056b3;
}

.users-container table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.users-container table th,
.users-container table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.users-container table th {
  background-color: #f0f4f8;
}

.users-container table td {
  background-color: #fff;
}

.users-container button {
  background-color: #0056b3;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.users-container button:hover {
  background-color: #004494;
}

.confirm-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirm-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.confirm-modal-content button {
  margin: 10px;
}
